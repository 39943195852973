import React from "react";
import Layout from "../components/layout";
import Head from "../components/head";

const interstitialPage = () => {
  return (
    <div>
      <Layout>
        <Head title="Contact" />
        <div class="section-page-header">
          <h2>Contact</h2>
          <p>
            Question? Comment? Send it to ericcogan@sent.com or message me on{" "}
            <a href="https://www.linkedin.com/in/ericcogan/">LinkedIn</a>.
          </p>
        </div>
      </Layout>
    </div>
  );
};

export default interstitialPage;
